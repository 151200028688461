import React, { createContext } from "react";
import { I18nProvider as LinguiProvider } from "@lingui/react";
import { defaultLanguage } from "src/utils/localization/languages/languages";
import { Language } from "src/utils/localization/languages/types";
import { i18n } from "@lingui/core";
import { en, es, de, fr, pt } from "make-plural/plurals";

import { messages as enMessages } from "../../../locales/en/messages";
import { messages as esMessages } from "../../../locales/es/messages";
import { messages as frMessages } from "../../../locales/fr/messages";
import { messages as deMessages } from "../../../locales/de/messages";
import { messages as ptBrMessages } from "../../../locales/pt-br/messages";

i18n.loadLocaleData({
  en: { plurals: en },
  es: { plurals: es },
  fr: { plurals: fr },
  de: { plurals: de },
  "pt-br": { plurals: pt }
});

i18n.load({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  en: enMessages as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  es: esMessages as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fr: frMessages as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  de: deMessages as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  "pt-br": ptBrMessages as any
});

// i18n.load("en", enMessages);
//

const LanguageContext = createContext<Language>(defaultLanguage as Language);
interface I18nProviderProps {
  language?: Language;
}

export const I18nProvider: React.FC<
  React.PropsWithChildren<I18nProviderProps>
> = ({ language = defaultLanguage, children }) => {
  i18n.activate(language.code);

  return (
    <LinguiProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <LanguageContext.Provider value={language}>
        {children}
      </LanguageContext.Provider>
    </LinguiProvider>
  );
};

export function useLanguage(): Language {
  return React.useContext(LanguageContext);
}

// async function dynamicActivate(locale: LanguageCode) {
//   const { messages } = await import(
//     /* webpackMode: "lazy", webpackChunkName: "locales-[request]" */ `../../../locales/${locale}/messages`
//   );
//   i18n.load(locale, messages);
//   i18n.activate(locale);
// }
