import React, { ReactNode } from "react";
import { i18n } from "@lingui/core";
import DateOnlyFormat from "components/DateOnlyFormat";
import { FormatDateFormat, toUTCDate } from "src/utils/date";

import type {
  AggregationType,
  TokenValuePercentage,
  TokenValue
} from "@silversea-ssc/dotcom-read-model-types";
import { t } from "@lingui/macro";
import { match } from "ts-pattern";
import { useGeolocation } from "src/utils/localization/geolocation/GeolocationContext";
import { Currency } from "src/utils/localization/currency";

const numberFormatOptions = (currency: string): Intl.NumberFormatOptions => ({
  style: "currency",
  currency: currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

type AmountPrefixProps = {
  aggregationType: AggregationType;
  aggregatedValuesIdentical?: boolean;
};

const AmountPrefix: React.FC<React.PropsWithChildren<AmountPrefixProps>> = ({
  aggregationType,
  aggregatedValuesIdentical
}) => {
  const prefix = formatAmountPrefix(aggregationType, aggregatedValuesIdentical);
  return prefix ? <React.Fragment>{prefix} </React.Fragment> : null;
};

const formatAmountPrefix = (
  aggregationType: AggregationType,
  aggregatedValuesIdentical?: boolean
) => {
  if (aggregationType === "min")
    return i18n._({
      id: aggregatedValuesIdentical ? t`token-value-at` : t`token-value-from`
    });
  if (aggregatedValuesIdentical) return null;
  if (aggregationType === "max") return i18n._({ id: t`token-value-up-to` });
  return null;
};

const dateFormatOptions: FormatDateFormat = {
  dateStyle: "long"
};

function formattedValue(
  _placeholder: string,
  value: TokenValue,
  currentCurrency: Currency | undefined
): JSX.Element {
  return match(value)
    .with({ type: "date" }, (v) => (
      <DateOnlyFormat value={toUTCDate(v.date)} format={dateFormatOptions} />
    ))
    .with({ type: "percentage" }, (v) => (
      <React.Fragment>{formatPercentage(v)}</React.Fragment>
    ))
    .with({ type: "amount" }, (v) => {
      const formattedValue = i18n.number(
        v.amount,
        numberFormatOptions(v.currency)
      );

      const extendedUSDCurrencySymbol =
        v.currency == "USD" && currentCurrency != "USD";
      const extendedFormattedValue = extendedUSDCurrencySymbol
        ? formattedValue.replace("$", "US$")
        : formattedValue;
      return (
        <React.Fragment>
          <AmountPrefix
            aggregationType={v.aggregationType}
            aggregatedValuesIdentical={v.aggregatedValuesIdentical}
          />
          {extendedFormattedValue}
        </React.Fragment>
      );
    })
    .with({ type: "string" }, (v) => <React.Fragment>{v.value}</React.Fragment>)
    .otherwise(() => <React.Fragment></React.Fragment>);
}

const formatPercentage = (token: TokenValuePercentage): string =>
  `${token.percentage}%`;

interface PlaceholderValuesFormatterProps {
  placeholder: string;
  value: TokenValue;
}

const PlaceholderValuesFormatter: React.FC<
  React.PropsWithChildren<PlaceholderValuesFormatterProps>
> = ({ placeholder, value }) => {
  const geolocation = useGeolocation();
  if (!value) return <React.Fragment>{placeholder}</React.Fragment>;

  return (
    <React.Fragment>
      {formattedValue(placeholder, value, geolocation.defaultCurrency)}
    </React.Fragment>
  );
};

export default PlaceholderValuesFormatter;

export function renderPlaceholderValue(
  placeholder: string,
  value: TokenValue
): ReactNode {
  return (
    <PlaceholderValuesFormatter
      key={`${placeholder}`}
      placeholder={placeholder}
      value={value}
    />
  );
}
