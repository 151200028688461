const inPageScrollIdentifier = "data-page-builder-anchor";

export const scrollToSection = (
  targetElement: HTMLDivElement | HTMLElement
): void => {
  setScrollOffset();
  targetElement.scrollIntoView({ block: "start", behavior: "smooth" });
};

export const scrollToSectionId = (targetElementId: string): void => {
  const element = document.getElementById(targetElementId);
  if (element) scrollToSection(element);
};

export const getAllAnchorableSections = (): NodeListOf<HTMLDivElement> =>
  document.querySelectorAll<HTMLDivElement>(`[${inPageScrollIdentifier}]`);

export const getAnchorableSectionByRefName = (
  navContainerItems: NodeListOf<HTMLDivElement>,
  target: string | undefined
): HTMLDivElement | undefined => {
  if (!target) return undefined;

  return navContainerItems
    ? Array.from(navContainerItems).find(
        (x) => x.getAttribute(inPageScrollIdentifier) == target
      )
    : undefined;
};

const getMainHeaderElement = () =>
  document.querySelector<HTMLElement>("header[data-main-header]");

const computeScrollHeaderOffset = (): number => {
  const stickyNav = document.querySelector<HTMLElement>(
    "[data-secondary-sticky-menu]"
  );
  const stickyNavContent = document.querySelector<HTMLElement>(
    "[data-secondary-sticky-menu-children]"
  );

  const mainHeader = getMainHeaderElement();
  const mainMenuOffset = computeMainNavOffset(mainHeader);
  const translateOffset = stickyNav?.clientHeight || 0;
  const translateContentOffset = stickyNavContent?.clientHeight || 0;
  return mainMenuOffset + translateOffset + translateContentOffset;
};

const isElementStickable = (element: HTMLElement | null): boolean =>
  element ? getComputedStyle(element).position == "sticky" : false;

export function setScrollOffset() {
  const offset = computeScrollHeaderOffset();
  document
    .querySelector<HTMLElement>(":root")
    ?.style?.setProperty("scroll-padding-top", offset + "px");

  const mainHeader = getMainHeaderElement();
  const mainHeaderTopOffset = computeMainNavOffset(mainHeader);

  document
    .querySelector<HTMLElement>(":root")
    ?.style?.setProperty(
      "--main-header-top-offset",
      `${mainHeaderTopOffset}px`
    );
}

const computeMainNavOffset = (mainHeader: HTMLElement | null): number => {
  if (!mainHeader) return 0;

  const isSticky = isElementStickable(mainHeader);
  return isSticky && mainHeader ? mainHeader.clientHeight : 0;
};
