type LocalStorageItem<T> = {
  get: () => T | undefined;
  set: (item: T) => void;
};

export const makeLocalStorageItem = <T>(
  itemName: string
): LocalStorageItem<T> => {
  if (!checkIfLocalStorageIsSupportedByBrowser()) return makeNoopLocalStorage();

  return {
    get(): T | undefined {
      return localStorageGet(itemName);
    },

    set(item: T): void {
      localStorageSet(itemName, item);
    }
  };
};

const localStorageGet = <T>(itemName: string): T | undefined => {
  const encodedItem = localStorage.getItem(itemName);
  return !encodedItem ? undefined : (JSON.parse(encodedItem) as T);
};

const localStorageSet = <T>(itemName: string, item: T): void => {
  localStorage.setItem(itemName, JSON.stringify(item));
};

// code from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
export const checkIfLocalStorageIsSupportedByBrowser = () => {
  const mod = "modernizr";
  try {
    window.localStorage.setItem(mod, mod);
    window.localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};

const makeNoopLocalStorage = <T>(): LocalStorageItem<T> => {
  return {
    get() {
      return undefined;
    },
    set(_item: unknown): void {
      //noop
    }
  };
};
