import React from "react";
import { useLanguage } from "../utils/localization/languages/I18nContext";
import { formatDate, FormatDateFormat } from "../utils/date";
interface DateOnlyFormatProps {
  value: Date | string | undefined;
  format: FormatDateFormat;
}

const DateOnlyFormat: React.FC<
  React.PropsWithChildren<DateOnlyFormatProps>
> = ({ value, format }) => {
  const language = useLanguage();
  if (!value) return null;

  return (
    <React.Fragment>
      {formatDate(value, language.ogLang, format)}
    </React.Fragment>
  );
};

export default React.memo(
  DateOnlyFormat,
  (prevProps, nextProps) => prevProps.value == nextProps.value
);
